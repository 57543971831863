import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo";
import { Link, graphql } from "gatsby"
import './post.css'
export default function BlogPost({ data }) {
  const post = data.markdownRemark
  const tags = post.frontmatter.tags
  const description = post.frontmatter.description
  return (
    <Layout>
      <SEO title={post.frontmatter.title}  description={description} />
      <div>
        <h1 id="page_title">{post.frontmatter.title}</h1>
        <div id="post_content" className="font-sans" dangerouslySetInnerHTML={{ __html: post.html }} />
        <div className="border-dashed border-t border-black">
          {!tags ? '' :
            <p className="italic">Tags: {tags.map((tag) => (<span key={"tag_"+tag}><Link to={"/blog/tag/" + tag + "#page_title"} className="underline">{tag}</Link> </span>))}</p>
          }
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html      
      frontmatter {
        title
        tags
        description
      }
    }
  }
`